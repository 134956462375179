import "owl.carousel/dist/owl.carousel.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";

import {
  FaHospital,
  FaClinicMedical,
  FaHospitalUser,
  FaMedkit,
  FaHospitalSymbol,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import png21 from "../../assets/images/png-2-1.png";
import welcome2 from "../../assets/images/welcome-img2-.jpg";
import SpendMoreTimeWithPatientsIcon from "../../assets/images/Spend-More-Time-With-Patients-Icon.png";
import IncreaseReferraleEfficiencyIcon from "../../assets/images/increase-referral-efficiency-icon.png";
import GrowthProfitabilityIcon from "../../assets/images/Growth-Profitability-Icon.png";
import StreamlineProductivityIcon from "../../assets/images/Streamline-Productivity-Icon.png";
import SignupTodayImg from "../../assets/images/sign-up-today-img.jpg";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from "jquery";
import { useEffect, useState } from "react";
window.jQuery = $;
window.$ = $;

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!window.$) {
        const script = document.createElement("script");
        script.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        script.async = true;
        script.onload = () => {
          loadOwlCarousel();
        };
        document.body.appendChild(script);
      } else {
        loadOwlCarousel();
      }
    }
  }, []);
  const loadOwlCarousel = () => {
    import("owl.carousel").then(() => {
      if (typeof window.$(".owl-carousel").owlCarousel === "function") {
        window.$(".owl-carousel").owlCarousel({
          responsiveClass: true,
          loop: true,
          margin: 40,
          smartSpeed: 800,
          autoplay: false,
          autoplayTimeout: 5000,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: 2,
            },
            1200: {
              items: 3,
            },
          },
        });
      }
    });
  };

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <section className="main-area">
        <div className="container max-w-screen-xl flex flex-col md:flex-row">
          <div className="w-full md:pt-36 float-left ">
            <h1 className="text-3xl md:text-6xl pt-4">
              Healthcare Referral Pro
            </h1>
            <h4 className="text-3xl">
              Professional & Secure Medical Referrals
            </h4>
            <div className="bottom-btn flex flex-row">
              <Link to={"/contact"} className="w-full md:w-fit md:mr-1">
                CONTACT US
              </Link>
              <Link to={"/register"} className="w-full md:w-fit md:ml-1">
                REGISTER
              </Link>
            </div>
          </div>
          <div className=" float-left">
            <div className="image-box">
              <Image src={png21} alt="" className=" pt-4 w-full" />
            </div>
          </div>
        </div>
      </section>
      <section className="mor-information">
        <div className="container max-w-screen-xl">
          <h3>Streamline Your Patient Referrals Today</h3>
          <Link to={"/contact"}>Request More Information</Link>
        </div>
      </section>
      <section className="content-box">
        <div className="container max-w-screen-xl p-3 ">
          <div className="row">
            <div className="col-sm-12 col-md-6" data-aos="fade-right">
              <p className="pt-5">
                Revolutionize the way you handle referrals with our innovative platform designed to save you valuable time, streamline the process effortlessly, and cut down on unnecessary expenses. Our system consolidates all necessary resources into one convenient location, eliminating the need for tedious manual searches and enabling quick access to essential information. By seamlessly identifying which providers accept a patient’s insurance, we ensure a smooth transition and offer unparalleled convenience in managing referrals.
              </p>
            </div>
            <div
              className="col-sm-12 col-md-6"
              data-aos="flip-left"
              data-aos-duration="1500"
            >
              <div className="image-box">
                <Image src={welcome2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="benifits">
        <div className="container max-w-screen-xl">
          <div className="title">
            <h2>The Benefits of Healthcare Referral Pro</h2>
          </div>
          <div className="row crow">
            <div className="col-sm-6 col-md-3 p15 ">
              <div
                className="box "
                data-aos="flip-left"
                data-aos-duration="1500"
              >
                <div className="icon">
                  <Image src={SpendMoreTimeWithPatientsIcon} alt="" />
                </div>
                <label>Spend More Time With Patients</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 p15">
              <div
                className="box "
                data-aos="flip-left"
                data-aos-duration="1500"
              >
                <div className="icon">
                  <Image src={IncreaseReferraleEfficiencyIcon} alt="" />
                </div>
                <label>Increase Referral Efficiency</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 p15">
              <div
                className="box "
                data-aos="flip-left"
                data-aos-duration="1500"
              >
                <div className="icon">
                  <Image src={GrowthProfitabilityIcon} alt="" />
                </div>
                <label>Grow Profitability</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 p15">
              <div
                className="box "
                data-aos="flip-left"
                data-aos-duration="1500"
              >
                <div className="icon">
                  <Image src={StreamlineProductivityIcon} alt="" />
                </div>
                <label>Streamline Productivity</label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="who-serve">
        <div className="container max-w-screen-xl">
          <div className="ctitle">
            <h2>Who We Serve</h2>
          </div>
          <div className="rslider">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="w-box">
                  <div className="tbox">
                    <FaHospital />
                    <span>01</span>{" "}
                  </div>
                  <label>Hospitals</label>
                  <p>
                    Transform your hospital with Healthcare Referral Pro’s
                    seamless referral system, allowing you to spend more quality
                    time with your patients. By streamlining the referral
                    process, you can focus on delivering exceptional care while
                    increasing efficiency and ultimately boosting profitability.
                  </p>
                  <Link to="/hospitals">Read More {">"}</Link>
                  {/* <a href="">Read More {">"} </a>{" "} */}
                </div>
              </div>
              <div className="item">
                <div className="w-box">
                  <div className="tbox">
                    <FaClinicMedical />
                    <span>02</span>{" "}
                  </div>
                  <label>Clinics</label>
                  <p>
                    Our Healthcare Referral Pro platform allows clinics to spend
                    more time focusing on their patients’ needs by streamlining
                    the referral process. By increasing referral efficiency,
                    healthcare providers can improve patient outcomes and
                    satisfaction while also growing their profitability.
                  </p>
                  <Link to="/clinics">Read More {">"}</Link>
                  {/* <a href="">Read More {">"} </a>{" "} */}
                </div>
              </div>
              <div className="item">
                <div className="w-box">
                  <div className="tbox">
                    <FaHospitalUser />
                    <span>03</span>{" "}
                  </div>
                  <label>Long Term Care Facilities</label>
                  <p>
                    Experience the freedom to spend more quality time with your
                    patients as Healthcare Referral Pro takes care of the
                    administrative burden. By streamlining referral processes
                    and increasing efficiency, you can enhance long term patient
                    care and satisfaction.
                  </p>
                  <Link to="/long-term-care-facilities">Read More {">"}</Link>
                  {/* <a href="">Read More {">"} </a>{" "} */}
                </div>
              </div>
              <div className="item">
                <div className="w-box">
                  <div className="tbox">
                    <FaMedkit />
                    <span>04</span>{" "}
                  </div>
                  <label>Home Healthcare Agencies</label>
                  <p>
                    Transform the way you work by harnessing the power of
                    Healthcare Referral Pro to streamline your processes and
                    increase productivity. Our platform is designed to simplify
                    home healthcare workflows, making it easier for you to
                    manage referrals efficiently and effectively.
                  </p>
                  <Link to="/home-healthcare-agencies">Read More {">"}</Link>
                  {/* <a href="">Read More {">"} </a>{" "} */}
                </div>
              </div>
              <div className="item">
                <div className="w-box">
                  <div className="tbox">
                    <FaHospitalSymbol />
                    <span>05</span>{" "}
                  </div>
                  <label>Hospice Agencies</label>
                  <p>
                    With Healthcare Referral Pro, increasing referral efficiency
                    is just the beginning. By simplifying and automating the
                    referral process, you can maximize profitability without
                    sacrificing quality of care for your hospice patients.
                  </p>
                  <Link to="/hospice-agencies">Read More {">"}</Link>
                  {/* <a href="">Read More {">"} </a>{" "} */}
                </div>
              </div>
              <div className="item">
                <div className="w-box">
                  <div className="tbox">
                    <FaClinicMedical />
                    <span>06</span>{" "}
                  </div>
                  <label>Walk In Clinics</label>
                  <p>
                    With Healthcare Referral Pro, you can streamline your
                    referral process and spend more quality time with those who
                    need your care. Increase efficiency and profitability while
                    providing top-notch medical referrals for walk-in clinics
                    with our professional and secure platform.
                  </p>
                  <Link to="/walk-in-clinics">Read More {">"}</Link>
                  {/* <a href="">Read More {">"} </a>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sign-up">
        <div className="container max-w-screen-xl">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="w47 m5" data-aos="flip-up" data-aos-duration="1500">
              <div className="img-box">
                <Image src={SignupTodayImg} alt="" />
              </div>
            </div>
            <div className="w47">
              <div className="sign-text">
                <h3 data-aos="fade-up" data-aos-duration="1500">
                  Sign up today
                </h3>
                <p data-aos="flip-up" data-aos-duration="1500">
                  Don’t wait for medical referrals to become a headache! Join
                  Healthcare Referral Pro today and experience seamless, secure,
                  and efficient healthcare connections like never before. The
                  future of your health coordination is just one click away –
                  act fast, sign up now!
                </p>
                <Link to={"/login"} data-aos="flip-up" data-aos-duration="1500">
                  Login / Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
