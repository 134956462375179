import axios from "axios";
import {
  cleanLocalStorage,
  errorToast,
  getJWTToken,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../helper/helper";

// "https://209.23.13.212/admin/api" ||
const BASE_URL =
  "https://healthcarereferralpro.com/admin/api" ||
  "https://hrp-preview.com/admin/api" ||
  "https://webpreviewtoday.com/healthcare/admin/api";
const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(
  function (config) {
    const token = getJWTToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      errorToast("Unauthorized access. Please login again.");
      cleanLocalStorage();
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default instance;
export const GetApi = (tag = "", isHeader = false) => {
  return instance
    .get(BASE_URL + tag, {
      headers: isHeader
        ? {
            Authorization: getJWTToken(),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

export const PostApi = (tag = "", reqBody, isHeader = false, flag) => {
  let flagCheck = flag ? "application/json" : "multipart/form-data";

  return instance
    .post(BASE_URL + tag, reqBody, {
      headers: isHeader
        ? {
            "Content-Type": flagCheck,
            accept: "multipart/form-data",
            Authorization: getJWTToken(),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    });
};

export const DeleteApi = (tag = "", isHeader = false) => {
  return axios
    .delete(BASE_URL + tag, {
      headers: isHeader
        ? {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: getJWTToken(),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

export const PutApi = (tag = "", reqBody, isHeader) => {
  const headers = {
    accept: "application/json",
    Authorization: getJWTToken(),
  };
  return instance
    .put(BASE_URL + tag, reqBody !== null && reqBody, {
      headers: isHeader ? headers : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const DownloadApi = (tag = "", fileName = "SosReport") => {
  return instance
    .get(BASE_URL + tag, {
      headers: {
        Authorization: getJWTToken(),
      },
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.warn(err);
    });
};
const ErrorHandler = async (e) => {
  console.log("error is", e);
  if (e.response?.data?.message) {
    if (e.response?.data?.code === 498) {
      RefreshToken();
    } else if (e.response?.data?.code === 401) {
      errorToast(e.response?.data?.message);
      cleanLocalStorage();
      window.location.href = "/login";
    } else if (e.response?.data?.status === 404) {
      return e.response.data;
    } else {
      errorToast(e.response?.data?.message);
      return e.response.data;
    }
  } else {
    console.log(e);
    errorToast("Something went wrong");
  }
};

const RefreshToken = async () => {
  await instance
    .post(
      `${BASE_URL}/refresh-token`,
      {},
      { headers: { "refresh-token": getLocalStorageItem("refreshToken") } }
    )
    .then(async (response) => {
      if (response.data.meta.code === 1) {
        setLocalStorageItem("token", response.data.meta.token);
        window.location.reload();
      }
    })
    .catch(async (error) => {
      if (error.response.data.code === 401) {
        await Api.logoutUser();
        cleanLocalStorage();
        window.location.href = "/login";
      } else {
        cleanLocalStorage();
        window.location.href = "/login";
      }
    });
};

export const Api = {
  login: (reqBody) => PostApi("/login", reqBody),
  register: (reqBody) => PostApi("/register", reqBody),
  getFacilityType: () => PostApi("/register-by"),
  forgotPassword: (reqBody) => PostApi("/forgot-password", reqBody),
  getStateList: (state_id) => PostApi(`/get-state?country_id=${state_id}`),
  getCityList: (country_id, state_id, perPage = 500, pageNo = 1, search) =>
    PostApi(
      `/get-city?country_id=${country_id}&state_id=${state_id}&per_page=${perPage}&page=${pageNo}&search=${search}`
    ),
  verifyEmail: (token) => PostApi(`/verify-account/${token}`),
  getProfile: () => PostApi("/get-profile", "", true),
  updateProfile: (reqBody) => PostApi("/update-profile", reqBody, true),
  getPlans: () => PostApi("/subscription-plans", "", true),
  ResetPassword: (reqBody) => PostApi("/reset-password", reqBody),
  getInsuranceList: () => PostApi("/insurance-list"),
  updateMembership: (reqBody) => PostApi("/update-membership", reqBody, true),
  addDoctor: (reqBody) => PostApi("/doctor/add", reqBody, true),
  editDoctor: (reqBody, id) => PostApi(`/doctor/edit/${id}`, reqBody, true),
  deleteDoctor: (id) => PostApi(`/doctor/delete/${id}`, "", true),
  getDoctorList: (perPage, pageNo, search) =>
    PostApi(
      `/doctor/list?per_page=${perPage}&page=${pageNo}&search=${search}`,
      "",
      true
    ),
  addReferralPatient: (reqBody) =>
    PostApi("/referral-management/add/patient-info", reqBody, true),
  addReferralInsurance: (reqBody) =>
    PostApi("/referral-management/add/medical-insurance", reqBody, true),
  searchMedicalFacility: (reqBody) =>
    PostApi(
      "/referral-management/add/search-medical-facilities",
      reqBody,
      true
    ),
  getReferralList: (perPage, pageNo, search) =>
    PostApi(
      `/referral-management/list?per_page=${perPage}&page=${pageNo}&search=${search}`,
      "",
      true
    ),
  searchFacilityType: (reqBody) =>
    PostApi("/search-by-facility-type", reqBody, true),
  getReferralById: (patientId) =>
    PostApi(`/referral-management/detail/${patientId}`, "", true),
  editReferralData: (reqBody, id) =>
    PostApi(`/referral-management/add/patient-info`, reqBody, true),
  referralDischargeLogistics: (reqBody) =>
    PostApi("/referral-management/add/discharge-logistics", reqBody, true),
  referralDocumentUpload: (reqBody) =>
    PostApi("/referral-management/add/documents", reqBody, true),
  deleteReferral: (id) =>
    PostApi(`/referral-management/delete/${id}`, "", true),
  downloadDocument: (url, fileName) => DownloadApi(`${url}`, fileName),
  referralDocumentDelete: (reqBody, id) =>
    PostApi(`/delete-document/${id}`, reqBody, true),
  referringClinicians: (reqBody) =>
    PostApi("/referral-management/add/referring-clinicians", reqBody, true),
  referralSend: (reqBody, id) =>
    PostApi(`/referral-management/add/send-referral`, reqBody, true),
  getUsersList: (perPage, pageNo, search) =>
    PostApi(
      `/delegate-access/list?per_page=${perPage}&page=${pageNo}&search=${search}`,
      "",
      true
    ),
  addDelegateUser: (reqBody) => PostApi("/delegate-access/add", reqBody, true),
  editDelegateUser: (reqBody, id) =>
    PostApi(`/delegate-access/edit/${id}`, reqBody, true),
  deleteDelegateUser: (id) =>
    PostApi(`/delegate-access/delete/${id}`, "", true),
  getIncomingReferralList: (perPage, pageNo, search) =>
    PostApi(
      `/incoming-referral/list?per_page=${perPage}&page=${pageNo}&search=${search}`,
      "",
      true
    ),
  eSignFetch: (reqBody) => PostApi("/e-sign/fetchdata", reqBody, true),
  eSignStore: (reqBody) => PostApi("/e-sign/store", reqBody, true),
  paymentIntent: (reqBody) => PostApi("/payment-intent", reqBody, true),
  sendContactUs: (reqBody) => PostApi("/contact-us", reqBody),
  sendNewsLetter: (reqBody) => PostApi("/newsletter", reqBody),
  opt: (reqBody) => PostApi("/twilio-opt", reqBody),
  changePassword: (reqBody) => PostApi("/change-password", reqBody, true),
  referAFacility: (reqBody) => PostApi("/refer-a-facility", reqBody, true),
  userList: (reqBody) => PostApi("/chat-user/list", reqBody, true),
  getUserConversionList: (reciver_id) =>
    PostApi(`/chat-user/messages?reciver_id=${reciver_id}`, "", true),
  clearChatMessage: (reqBody) =>
    PostApi("/chat-user/chat-clear", reqBody, true),
  sendAttachment: (reqBody) =>
    PostApi("/chat-user/send-attachment", reqBody, true),
};
